import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { config } from 'src/app/valueObject/config';


@Injectable({
  providedIn: 'root'
})
export class EnitComplianceServiceService {

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };
  mlaiList: any[] = []
  cisList: any[] = []
  config: any = config;
  constructor(private http: HttpClient) { }
  adminRequestType:Array<{id:number, name: string}>=Array(
    {"id":1,"name":"2022"},
    {"id":2,"name":"2023"},
    {"id":3,"name":"2024"},
    {"id":4,"name":"2025"},
    {"id":5,"name":"2026"},
    {"id":6,"name":"2027"},
    {"id":7,"name":"2028"},
    {"id":8,"name":"2029"},
    {"id":9,"name":"2030"}

  );


  memberEmailNotification:Array<{id:number, name: string}>=Array(
    {"id":1,"name":"AL_ML_Quarter_Member Alert"},
    {"id":2,"name":"Network Solution"},
    {"id":3,"name":"Cyber Security"}
  );

  adminPeriodType:Array<{id:number ,name: String}>=Array(
    {"id":1,"name":"HALF YEARLY"},
    {"id":2,"name":"QUARTER"},
    {"id":3,"name":"YEARLY"},
  )

  cutOffDayType:Array<{id:number,name:string}>=Array(
    {"id":1,"name":"ML & AI"}
    //{"id":2,"name":"CYBER SECURITY"}
  )

  
  // shravan siddhesh
  getAimlEligibleData(requestObject) {
    let URL = environment.URL + this.config.URL.getAimlEligibleData;
    // this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  getQuarterDetails() {
    let URL = environment.URL + this.config.URL.getQuarterDetails;
    return this.http.get(URL, this.options);
  }
  deleteQuarterTradeUser(quarterId:any) {
    let URL = environment.URL + this.config.URL.deleteQuarterTradeUser+"?quarterId="+quarterId;
    return this.http.post(URL, null, this.options);
  }

  getComplianceMemberDetails() {
    let URL = environment.URL + this.config.URL.getComplianceMemberDetails;
    return this.http.get(URL, this.options);
  }

  addEligibleTradeUser(requestObject) {
    let URL = environment.URL + this.config.URL.addQuarterTradeUser;
    return this.http.post(URL, requestObject, this.options)
  }

  notifyAllTradeUser(requestObject) {
    let URL = environment.URL + this.config.URL.notifyAllTradeUser;
    return this.http.post(URL, requestObject, this.options)
  }

  notifyTradeUser(requestObject) {
    let URL = environment.URL + this.config.URL.notifyTradeUser;
    return this.http.post(URL, requestObject, this.options)
  }
  
  aiMlFileUpload(file: File) {
    let URL = environment.URL + this.config.URL.aiMlFileUpload;
    let formParams = new FormData;
    formParams.append('file', file);
    formParams.append('fileType','AIML');
    return this.http.post(URL, formParams, this.options);
  }
  //Cyber-Security
  getAllCyberSecurityEnabledMembers(requestObject)
  {
    let URL = environment.URL + this.config.URL.getAllCyberSecurityEnabledMembers;
    return this.http.post(URL, requestObject, this.options)
  }

  removeCyberSecurityEnabledMember(requestObject) {
    let URL = environment.URL + this.config.URL.removeCyberSecurityEnabledMember;
    return this.http.post(URL, requestObject, this.options);
  }

  enableCyberSecurityEnabledMember(requestObject)
  {
    let URL = environment.URL + this.config.URL.enableCyberSecurityEnabledMember;
    return this.http.post(URL, requestObject, this.options);
  }

  getCyberSecurityMemberDetails() {
    let URL = environment.URL + this.config.URL.getCyberSecurityMemberDetails;
    return this.http.get(URL, this.options);
  }

  cyberSecurityFileUpload(file: File) {
    let URL = environment.URL + this.config.URL.aiMlFileUpload;
    let formParams = new FormData;
    formParams.append('file', file);
    formParams.append('fileType','CyberSecurity');
    return this.http.post(URL, formParams, this.options);
  }

  notifyCyberSecurityUser(requestObject) {
    let URL = environment.URL + this.config.URL.notifyCyberSecurityUser;
    return this.http.post(URL, requestObject, this.options)
  }

  notifyAllCyberSecurityUser(requestObject) {
    let URL = environment.URL + this.config.URL.notifyAllCyberSecurityUser;
    return this.http.post(URL, requestObject, this.options)
  }

  getFileDownloadable()
  {
    let URL = environment.URL + this.config.URL.downloadAIMLCyberFile;
    return this.http.get(URL, this.options);
  }

  //mansi
  getQuarterDropDowndata() {
    let URL = environment.URL + this.config.URL.getQuarterDropDowndata;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }


  addMlAiList(mlaiList: any[]) {
    this.mlaiList = mlaiList;
  }

  getAllMLAI() {
    return this.mlaiList;
  }

  getMLAIByRef(id: number) {
    return this.mlaiList.find(data => data.mlaiRefNO == id);
  }

  getMLandAiReport(requestObject) {
    let URL = environment.URL + this.config.URL.getMLandAiReport;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }
  // vedanti 
  getMemberDetails(memCode: String, memId: number) {
    let URL = environment.URL + this.config.URL.getMemberDetails + "?" + "memCode=" + memCode + "&" + "memId=" + memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options)
  }

  getMlAiData(requestObject) {
    let URL = environment.URL + this.config.URL.getMlAiData;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options)
  }

  saveMlAiRequest(requestObject) {
    let URL = environment.URL + this.config.URL.saveMlAiRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options)
  }

  getEligibility(memId: number){
    let URL = environment.URL + this.config.URL.getEligibility + "?" + "memId=" + memId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options)
  }

  saveMlAiNillRequest(requestObject){
    let URL = environment.URL + this.config.URL.saveMlAiNillRequest;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options)
  }

  getCutOffDate(quarter: string, currentYear: string, module: string){
    let URL = environment.URL + this.config.URL.getCutOffDate + "?" + "quarter=" + quarter + "&" + "year=" + currentYear +"&"+ "module=" + module  ;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options)
  }

  getQuarterForSubmission(){
    let URL = environment.URL + this.config.URL.getQuarterForSubmission;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options)
  }

  getDataByRefId(requestObject){
    let URL = environment.URL + this.config.URL.getDataByRefId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options)
  }

  getFormSubmissionStatus(requestObject){
    let URL = environment.URL + this.config.URL.getFormSubmissionStatus;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options)
  }

  // ashwini nigel
  saveForEmailNotification(requestObject) {
    let URL = environment.URL + this.config.URL.saveForEmailNotification;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  getPeriodList(requestObject) {
    let URL = environment.URL + this.config.URL.getPeriodList;
    //let URL = 'http://localhost:8096/compliance/getDetailsForPeriod';
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

    // ashwini abhijeet
  saveForPeriodList(requestObject) {
    let URL = environment.URL + this.config.URL.saveForPeriodList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options);
  }

  viewExchangeEmailId() {
    let URL = environment.URL + this.config.URL.viewExchangeEmailId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options);
  }

  editExchangeEmailId(requestObject) {
    let URL = environment.URL + this.config.URL.editExchangeEmailId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, requestObject, this.options);
  }

  deleteExchangeEmailId(deleteReqData) {
    let URL = environment.URL + this.config.URL.deleteExchangeEmailId;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL, deleteReqData, this.options);
  }

  getFunctionality() {
    let URL = environment.URL + this.config.URL.getFunctionality;
    //let URL = 'http://localhost:8096/compliance/viewFunctionalityList'
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getNewFunctionality() {
    let URL = environment.URL + this.config.URL.getNewFunctionality;
    //let URL = 'http://localhost:8096/compliance/viewFunctionalityList'
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  getTokenList() {
    let URL = environment.URL + this.config.URL.viewEmailTokenList;
    // let URL= 'http://localhost:8096/compliance/viewEmailTokenList'
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, this.options);
  }

  viewEmailList(requestObject) {
    let URL = environment.URL + this.config.URL.viewEmailList;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.get(URL, requestObject);
  }


  saveEmailTemplate(requestObject) {
    let URL = environment.URL + this.config.URL.saveEmailTemplate;
    // let URL= 'http://localhost:8096/compliance/addEmailTemplate'
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options)
  }

  deleteEmailTemplate(requestObject) {
    let URL = environment.URL + this.config.URL.deleteEmailTemplate;
    // let URL= 'http://localhost:8096/compliance/deleteEmailTemplate'
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options)
  }

  editEmailTeamplate(requestObject) {
    let URL = environment.URL + this.config.URL.editEmailTemplate;
    // let URL= 'http://localhost:8096/compliance/editEmailTemplate'
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options)
  }

   // ashwini abhijeet
  saveForCutOffDate(requestObject) {
    let URL = environment.URL + this.config.URL.saveForCutOffDate;
     this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
     return this.http.post(URL,requestObject, this.options);
   }
  
  
   viewCutOffDate(){
    let URL = environment.URL + this.config.URL.viewCutOffDate;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options); 
  }
  
   editCutOffDate(requestObject){
    let URL = environment.URL + this.config.URL.editCutOffDate;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.post(URL,requestObject, this.options); 
  }

  viewPeriodData(){
    let URL = environment.URL + this.config.URL.viewPeriodData;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    return this.http.get(URL, this.options); 
  }

  addCyberSecurity(cisList: any[]) {
    this.cisList = cisList;
  }

  getaddCyberSecurity() {
    return this.cisList;
  }

  getaddCyberSecurityByRef(id: number) {
    return this.cisList.find(data => data.incidentRequestId == id);
  }


  getCyberSecurityReport(requestObject){
    let URL = environment.URL + this.config.URL.getCyberSecurityReport;
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    return this.http.post(URL, requestObject, this.options);
  }

  addcisList(cisList: any[]) {
    this.cisList = cisList;
  }

  //abhijeet
 viewModuleData(){
  let URL = environment.URL + this.config.URL.viewModuleData;
  this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
  return this.http.get(URL, this.options);
}
  getAllMembers(){
  let URL = environment.URL + this.config.URL.getAllMembers;
  this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
  return this.http.get(URL, this.options);
  }

  // uploadCyberSecurityReport(file: File){
  //   let URL = environment.URL + this.config.URL.uploadCyberSecurityReport;
  //   this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));

  //   // if (userControlId) {
  //   //   this.options.headers = this.options.headers.set('userId', userControlId);
  //   // } else {
  //   //   this.options.headers = this.options.headers.delete('userId');
  //   // }

  //   const formData: FormData = new FormData();
  //   formData.append('file', file);
  //   //formData.append('fileType', fileType)
  //   return this.http.post(URL, formData,this.options);
  // }

  uploadCyberSecurityReport(file: File, entityCode :string){

    let URL = environment.URL + this.config.URL.uploadCyberSecurityReport+"/?userId="+entityCode;
    
    this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
    
     const formData: FormData = new FormData();
    
     formData.append('file', file);
    
    //  formData.append('userId', entityCode);
    
     //   //formData.append('fileType', fileType)
    
    return this.http.post(URL, formData,this.options);
    
    }

    
    //ashwini
    viewMemeberNetworthList(){
      let URL = environment.URL + this.config.URL.viewMemeberNetworthList;
      this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
      return this.http.get(URL, this.options);
    }

    deleteNetworthMember(memCatId :any){
      let URL = environment.URL + this.config.URL.deleteNetworthMember+"/?memCatId="+memCatId;
      //let URL = environment.URL + this.config.URL.deleteNetworthMember;
      this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
      //const formData: FormData = new FormData();
    
      //formData.append('memCatId', memCatId);

      return this.http.post(URL,this.options);
    }

    editNetworth(requestObject){
      let URL = environment.URL + this.config.URL.editNetworthList;
      this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
      return this.http.post(URL,requestObject,this.options);

    }

    saveForNetworth(requestObject){
      let URL = environment.URL + this.config.URL.saveForNetworth;
      this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
      return this.http.post(URL,requestObject,this.options);

    }

    deleteCutOffYear(requestObject){
      let URL = environment.URL + this.config.URL.deleteCutOffYear;
      this.options.headers = this.options.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'));
      return this.http.post(URL,requestObject,this.options);


    }

}
